@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-Thin.woff2) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-ExtraLight.woff2) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-Light.woff2) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-Regular.woff2) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-Medium.woff2) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-SemiBold.woff2) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-Bold.woff2) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-ExtraBold.woff2) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: Poppins;
  src: url(/src/assets/Poppins/Poppins-Black.woff2) format("truetype");
  font-weight: 900;
}

body {
  font-family: "Poppins";
  overflow-y: hidden;
}

.App {
  display: flex;
  text-align: center;
  flex: 1;
  height: 100dvh;
  background-color: white;
  font-size: calc(10px + 2vmin);
  color: white;
  align-items: center;
  flex-direction: column;
}

/* styling the math symbols */
mjx-container {
  color: #252525;
}

p {
  /* color: #252525; */
}

span {
  color: #252525;
}


.menu_enter {
  /* animation: menu-enter 0.25s linear; */
  /* property name | duration | easing function */
  transition: position 0.25s;
}

.menu_exit {
  /* animation: menu-exit 02.5s linear; */
  transition: position 0.25s;
}

.App-header {
  background-color: white;
  /* min-height: 100dvh; */
  /* height: "100%"; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

select:hover,
button:hover {
  cursor: pointer;
}

#lecture_name::placeholder {
  color: gray;
}

.App-link {
  color: #61dafb;
}


#feedback_button:hover {
  cursor: pointer;
}


@keyframes zoom {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes menu-enter {
  from {
    transform: translate(-208px);
  }

  to {
    transform: translate(0px);
  }
}

@keyframes menu-exit {
  from {
    transform: translate(0px);
  }

  to {
    transform: translate(-208px);
  }
}

#payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
}

#payment-message {
  color: white;
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
  margin-bottom: 16px;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
/* don't apply to the buttons in the DatePicker */
button:not(.MuiInputBase-root button, .base-Popper-root button) {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

@keyframes zoom-in-and-out {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.0);
  }
}

/* Scroll bar  */
.scrollbar *::-webkit-scrollbar {
  width: 12px;
  border-radius: 8px;
  visibility: hidden;
}

.darkMode-scrollbar * ::-webkit-scrollbar {
  background: #333333;
}

.lightMode-scrollbar * ::-webkit-scrollbar {
  background: #E0E0E0;
}

.visible-scrollbar::-webkit-scrollbar:hover {
  visibility: visible;
}

/* Scroll bar thumb */
.scrollbar *::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: content-box;
  visibility: hidden;
}

.darkMode-scrollbar-thumb * ::-webkit-scrollbar-thumb {
  background-color: #666666;
}

.lightMode-scrollbar-thumb * ::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
}

.visible-scrollbar-thumb::-webkit-scrollbar-thumb {
  visibility: visible;
}


.card-info {
  padding: 12px;
  border: 1px solid gray;
  border-radius: 4px;
}

div.card-info.StripeElement.StripeElement--focus {
  padding: 12px;
  border: 1px solid black;
  border-radius: 4px;
}

div.card-info.dark-mode.StripeElement.StripeElement--focus {
  padding: 12px;
  border: 1px solid white;
  border-radius: 4px;
}

div.card-info.StripeElement.StripeElement--invalid {
  border: 1px solid red;
}

/* for upload slides */
canvas.react-pdf__Page__canvas {
  margin-bottom: 8px;
}